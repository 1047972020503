<i18n lang="yaml">
pt:
  categoryLocked: 'Esse app está bloqueado para edição.'
  irreversible: 'Essa ação é irreversível!'
  lockReason: 'A edição está bloqueada para que futuras versões e melhorias deste app possam ser instaladas automaticamente.'
  unlockTerms: 'Caso opte por desbloquear, você poderá editar os recursos deste app, no entanto'
  unlock: 'Desbloquear'
  warning: 'futuras atualizações deste app não serão automaticamente aplicadas na sua área de trabalho.'
en:
  categoryLocked: 'This app is locked for editing.'
  irreversible: 'This action is irreversible!'
  lockReason: 'Editing is blocked so that future app versions and improvements can be automatically applied to your workspace.'
  unlockTerms: 'Once unlocked, you may edit the resources in this app, however'
  unlock: 'Unlock'
  warning: 'it will not be eligible for future updates.'
</i18n>

<template>
  <div class="category-lock">
    <v-container class="category-lock__card">
      <v-card :loading="isLoading">
        <v-card-text>
          <div class="category-lock__title">
            {{ t('categoryLocked') }}
          </div>

          {{ t('lockReason') }}
          {{ t('unlockTerms') }}

          <div class="category-lock__warning">
            {{ t('warning') }}
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <deck-alert
            kind="warning"
            size="small"
            :text="t('irreversible')"
          />

          <deck-button
            kind="secondary"
            @click="unlockCategory"
          >
            {{ t('unlock') }}
          </deck-button>
        </v-card-actions>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from '~/assets/javascript/modules/vuex';
import DeckButton from '~/deck/button';
import DeckAlert from '~/deck/alert';

export default {
  name: 'CategoryLock',
  components: { DeckButton, DeckAlert },
  setup() {
    return {
      t: useI18n().t,
      ...mapGetters('workspace', ['currentCategory']),
      ...mapActions('workspace', ['loadWorkspace']),
    };
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    async unlockCategory() {
      const params = {
        current_name: this.currentCategory.category_name,
        locked: false,
      };

      try {
        this.isLoading = true;
        await this.$api.patch('/builder/categories', { body: { update_category: params } });
        await this.loadWorkspace({ builder: true, waitLoad: true });
      } catch (error) {
        this.$errorRescue(this, error, 'unlockCategory');
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.category-lock {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  padding-block: 16px;
  backdrop-filter: blur(3px);
  overflow: auto;
  z-index: 101;

  @supports (height: 100dvh) {
    width: 100dvw;
    height: 100dvh;
  }
}

.category-lock__card {
  max-width: 800px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 var(--z-s1) var(--z-s4) 0 var(--z-theme-shadow);
  opacity: 1;
}

.category-lock__title {
  color: black;
  font-size: 24px;
  font-weight: 800;
}

.category-lock__warning {
  font-size: 14px;
  font-weight: 800;
}
</style>

<template>
  <v-app>
    <template v-if="!inIframe">
      <ThePaywall />
      <CategoryLock v-if="currentCategory?.locked" />
      <StudioTopbar :transparent="false" />
    </template>

    <v-main>
      <NuxtPage />
    </v-main>

    <NewVersionAlert v-if="!inIframe" />

    <Snackbar />
    <TheDialog />
  </v-app>
</template>

<script>
import { mapGetters, mapMutations, mapState } from '~/assets/javascript/modules/vuex';
import Snackbar from '~/components/Snackbar';
import TheDialog from '~/components/dialogs/the-dialog';
import NewVersionAlert from '~/components/NewVersionAlert';
import StudioTopbar from '~/components/topbars/studio-topbar';
import ThePaywall from '~/components/the-paywall';
import CategoryLock from '~/components/studio/category-lock';

export default defineComponent({
  name: 'Studio',
  components: { TheDialog, Snackbar, StudioTopbar, NewVersionAlert, ThePaywall, CategoryLock },
  setup() {
    useWorkspace({ builder: true });

    buildHead();

    return {
      ...mapState('environment', ['inIframe']),
      ...mapMutations('studio', ['setInStudio']),
      ...mapMutations('environment', ['setCurrentEnvironment']),
      ...mapGetters('workspace', ['currentCategory']),
    };
  },
  beforeMount() {
    this.setInStudio();
    this.setCurrentEnvironment('studio');
  },
});
</script>
<style lang="scss">
@supports not (height: 100dvh) {
 /**
  * All scrollable contents in the studio area are confined to specific elements,
  * so if `dvh` unit is not supported, it's presumably safe to disable scrolling
  * on the body and html elements to avoid mobile browsers address bar on top of
  * the page and having a double overflow.
  *
  * PSA: in `__generic.scss` is where we set the `html` height to `100dvh`
  */
  body, html {
    overflow: hidden;
  }
}

// TODO: Studio navigation sidebars styles should be confined in a common wrapper component used in this layout
.builder-navigation {
  background-color: var(--z-theme-surface);
  overflow-y: hidden;
  height: 100%;
}

.builder-navigation--left {
  border-right: thin solid var(--z-theme-background-secondary);
  border-top: thin solid var(--z-theme-background-secondary);
}

.builder-navigation--right {
  border-left: thin solid var(--z-theme-background-secondary);
  border-top: thin solid var(--z-theme-background-secondary);
}

// TODO: These were written with the intention for inner studio components to
// use them, but should not have been done that way. Refactor when possible.
#builderArea {
  width: 100vw;
  display: flex;
  flex-direction: row;
  min-height: 100%;
}

#builderContent {
  background-color: var(--z-theme-background-secondary);
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0;
  position: relative;
  border-top: thin solid rgba(0, 0, 0, 0.12);
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
